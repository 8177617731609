import {observe} from '@github/selector-observer'

// Show mini profile sticky bar when the name is scrolled to the top.
observe('.js-user-profile-sticky-fields.is-stuck', function () {
  const stickyBar = document.querySelector<HTMLElement>('.js-user-profile-sticky-bar')
  return {
    add() {
      stickyBar?.classList.add('is-stuck')
    },
    remove() {
      stickyBar?.classList.remove('is-stuck')
    },
  }
})

// Show mini follow button when the big button is scrolled to the top.
observe('.js-user-profile-follow-button.is-stuck', function () {
  const stickyBar = document.querySelector<HTMLElement>('.js-user-profile-sticky-bar')
  return {
    add() {
      stickyBar?.classList.add('is-follow-stuck')
    },
    remove() {
      stickyBar?.classList.remove('is-follow-stuck')
    },
  }
})

import {fetchSafeDocumentFragment} from '@github-ui/fetch-utils'
import {observe} from '@github/selector-observer'
import {on} from 'delegated-events'
import {addUrlToHistoryStack} from '@github-ui/history'
import {remoteForm} from '@github/remote-form'
import {focusZone, type Direction, FocusKeys} from '@primer/behaviors'

let previousDay: Date | null = null

const currentTooltip = document.createElement('div')
currentTooltip.classList.add('svg-tip', 'svg-tip-one-line')
// Remove pointer events to prevent tooltip flickering
currentTooltip.style.pointerEvents = 'none'
currentTooltip.hidden = true

// Add the tooltip to
document.body.appendChild(currentTooltip)

function baseURL(): URL {
  const contributionGraph = document.querySelector<HTMLElement>('.js-calendar-graph')!
  const url = contributionGraph.getAttribute('data-url')!
  return new URL(url, window.location.origin)
}

observe('.js-calendar-graph-table', function (el) {
  const container = el.closest<HTMLElement>('.js-calendar-graph')!

  for (const cell of el.querySelectorAll<HTMLElement | SVGElement>('[data-level]')) {
    cell.addEventListener('click', selectCell)

    // SVG elements will become focusable if you add a focus even to them in Chrome, even if you don't add a tabindex
    if (cell.hasAttribute('tabindex')) {
      cell.addEventListener('keypress', selectCell)
    }
  }

  const fromStr = container.getAttribute('data-from')
  if (fromStr) {
    previousDay = utcDate(fromStr)
  }

  if (el instanceof HTMLElement) {
    focusZone(el, {
      focusInStrategy: 'previous',
      getNextFocusable,
      bindKeys: FocusKeys.ArrowAll | FocusKeys.HomeAndEnd | FocusKeys.PageUpDown,
      focusOutBehavior: 'stop',
    })
  }
})

function selectCell(event: Event) {
  // eslint-disable-next-line @github-ui/ui-commands/no-manual-shortcut-logic
  if ('key' in event && event.key !== 'Enter') return

  const cell = event.currentTarget

  if (!((cell instanceof HTMLElement || cell instanceof SVGElement) && cell.matches('[data-level]'))) return

  const graph = cell.closest<HTMLElement>('.js-calendar-graph')!
  const org = graph.getAttribute('data-org')!
  const iso = cell.getAttribute('data-date')!
  const selected = cell.classList.contains('active')
  const shiftKey = 'shiftKey' in event && event.shiftKey === true

  if (selected) {
    loadYearUrl(currentSelectedYearUrl())
  } else {
    // shift click to select a range of squares
    rangeSelected(utcDate(iso), shiftKey, org)
  }
}

function getNextFocusable(
  direction: Direction,
  from: Element | undefined,
  {key, ctrlKey}: KeyboardEvent,
): HTMLElement | undefined {
  const anyCellSelector = '[data-level]'

  // We would ideally check if `tabindex` is set to ensure the cell is focusable, but focusZone uses a roving tabindex
  // strategy that means the attribute is not always constant on each cell. So instead just check for [data-level] - the
  // important thing is to exclude the blank 'filler' cells.
  const ensureFocuseableCell = (element: Element | null | undefined) =>
    element instanceof HTMLTableCellElement && element.matches(anyCellSelector) ? element : undefined

  const querySelectorLast = (element: Element | null | undefined, selector: string) => {
    const all = element?.querySelectorAll(selector) ?? []
    return Array.from(all).at(-1)
  }

  if (from instanceof HTMLTableCellElement) {
    const sameColSelector = `[data-ix="${from.getAttribute('data-ix')}"]`
    const fromRow = from.parentElement
    const tableBody = fromRow?.parentElement

    // focusZone only supports 1D navigation, so we can't use `direction` and have to read the key itself
    // fallback to current cell for arrow keys to avoid wrapping to the next row/column
    switch (key) {
      case 'ArrowLeft':
        return ensureFocuseableCell(from.previousElementSibling) ?? from
      case 'ArrowRight':
        return ensureFocuseableCell(from.nextElementSibling) ?? from
      case 'ArrowDown':
        return ensureFocuseableCell(fromRow?.nextElementSibling?.querySelector(sameColSelector)) ?? from
      case 'ArrowUp':
        return ensureFocuseableCell(fromRow?.previousElementSibling?.querySelector(sameColSelector)) ?? from
      case 'Home':
        return ensureFocuseableCell(
          // if ctrlKey, focus first cell in first row
          ctrlKey ? tableBody?.querySelector(anyCellSelector) : fromRow?.querySelector(anyCellSelector),
        )
      case 'End':
        return ensureFocuseableCell(
          // if ctrlKey, focus last cell in last row
          ctrlKey ? querySelectorLast(tableBody, anyCellSelector) : querySelectorLast(fromRow, anyCellSelector),
        )
      case 'PageUp':
        return ensureFocuseableCell(tableBody?.querySelector(sameColSelector))
      case 'PageDown':
        return ensureFocuseableCell(querySelectorLast(tableBody, sameColSelector))
    }
  }
}

async function loadContributionActivity(url: string) {
  const container = document.getElementById('js-contribution-activity')
  if (!container) {
    return
  }

  container.classList.add('loading')
  const html = await fetchSafeDocumentFragment(document, url)
  container.classList.remove('loading')
  container.textContent = ''
  container.append(html)
}

type ContributionURLProperties = {
  from?: Date | null
  to?: Date | null
  fromStr?: string
  toStr?: string
  org?: string | null
  forceLocalTime?: boolean | null
}

// Assemble URL parameters for fetching contributions with 'from', 'to', and 'org' keys.
function buildContributionsURLParams(search: string, options: ContributionURLProperties) {
  const params = new URLSearchParams(search)
  params.delete('from')
  params.delete('to')
  params.delete('org')

  let fromStr = options.fromStr
  if (options.from) {
    fromStr = isoDate(options.from, !!options.forceLocalTime)
  }
  if (fromStr) {
    params.append('from', fromStr)
  }

  let toStr = options.toStr
  if (options.to) {
    toStr = isoDate(options.to, !!options.forceLocalTime)
  }
  if (toStr) {
    params.append('to', toStr)
  }

  const org = options.org
  if (org) {
    params.append('org', org)
  }

  return params
}

// Reloads the contribution graph with data from the specified date range and organization.
async function reloadYearlyContributions(from: Date, to: Date, org: string | null | undefined) {
  const calendarGraph = document.querySelector<HTMLElement>('.js-calendar-graph')!
  const graphURLStr = calendarGraph.getAttribute('data-graph-url')!

  const graphURL = new URL(graphURLStr, window.location.origin)
  const graphParams = buildContributionsURLParams(graphURL.search.slice(1), {from, to, org, forceLocalTime: true})
  graphURL.search = graphParams.toString()

  const html = await fetchSafeDocumentFragment(document, graphURL.toString())
  document.querySelector<HTMLElement>('.js-yearly-contributions')!.replaceWith(html)
}

// Highlight the contribution squares of the supplied date range
function selectContributionRange(from?: Date, to?: Date) {
  const calendar = document.querySelector<HTMLElement>('.js-calendar-graph')!
  const squares = calendar.querySelectorAll('[data-level]')

  for (const el of squares) {
    el.classList.remove('active')
    if (el.hasAttribute('aria-selected')) el.setAttribute('aria-selected', 'false')
  }
  calendar.classList.remove('days-selected')

  if (!(from || to)) {
    return
  }

  calendar.classList.add('days-selected')

  function filter(el: Element) {
    const millis = utcDate(el.getAttribute('data-date') || '').getTime()
    if (from && to) {
      return from.getTime() <= millis && millis <= to.getTime()
    } else if (from) {
      return millis === from.getTime()
    }
  }

  for (const el of squares) {
    if (filter(el)) {
      el.classList.add('active')
      if (el.hasAttribute('aria-selected')) el.setAttribute('aria-selected', 'true')
    }
  }
}

type RangeStringProperties = {
  first: string
  last: string
}

// Get the date range in strings from the browser URL.
function getBrowserUrlDateRange(): RangeStringProperties | null {
  const searchParams = new URLSearchParams(window.location.search.slice(1))
  const first = searchParams.get('from')
  const last = searchParams.get('to')

  return first && last ? {first, last} : null
}

// Get the date range in strings currently selected on the graph.
function getGraphSelectedDateRange(): RangeStringProperties | null {
  const calendar = document.querySelector<HTMLElement>('.js-calendar-graph')!
  const activeCells = calendar.querySelectorAll('.active')
  const firstCell = activeCells[0]
  const lastCell = activeCells[activeCells.length - 1]

  const first = firstCell && firstCell.getAttribute('data-date')!
  const last = lastCell && lastCell.getAttribute('data-date')!

  return first && last ? {first, last} : null
}

// Get the date range in strings from the year selector.
function getYearSelectorDateRange(): RangeStringProperties | null {
  const yearSelectedURL = new URL(currentSelectedYearUrl(), window.location.origin)
  const searchParams = new URLSearchParams(yearSelectedURL.search.slice(1))
  const first = searchParams.get('from')
  const last = searchParams.get('to')

  return first && last ? {first, last} : null
}

// Get the date range in strings that the graph is currently displaying.
function getCurrentGraphDateRange(): RangeStringProperties {
  const calendar = document.querySelector<HTMLElement>('.js-calendar-graph')!
  const first = calendar.getAttribute('data-from')!
  const last = calendar.getAttribute('data-to')!

  return {first, last}
}

// Get the current selected date range.
// This prioritizes date selection from the following:
// selected graph range > browser url date > year selector date
function getSelectedDateRange(): RangeStringProperties {
  const range = getGraphSelectedDateRange() || getBrowserUrlDateRange() || getYearSelectorDateRange()
  return range!
}

function pad(num: number): string {
  return `0${num}`.slice(-2)
}

// Format a date as YYYY-MM-DD.
function isoDate(date: Date, forceLocalTime: boolean): string {
  if (forceLocalTime) {
    return `${date.getFullYear()}-${pad(date.getMonth() + 1)}-${pad(date.getDate())}`
  }
  return `${date.getUTCFullYear()}-${pad(date.getUTCMonth() + 1)}-${pad(date.getUTCDate())}`
}

// Parse date in ISO 8601 format: 2015-10-20. Avoids cross-browser time zone
// problems interpreting the intent, local vs utc, of `new Date('2015-10-20')`.
function utcDate(iso: string): Date {
  const [year, month, date] = iso.split('-').map(x => parseInt(x, 10))
  return new Date(Date.UTC(year!, month! - 1, date))
}

// Highlight either the selected day or selected date range
function rangeSelected(selectedDay: Date, selectRange: boolean, org: string) {
  let from
  let to

  if (previousDay && selectRange) {
    const previousDayTime = previousDay.getTime()
    const monthInterval = 2678400000 // 31 days * 24 hours * 60 minutes * 60 seconds * 1000 ms
    const minRange = previousDayTime - monthInterval
    const maxRange = previousDayTime + monthInterval
    ;[from, to] = selectedDay > previousDay ? [previousDay, selectedDay] : [selectedDay, previousDay]

    from = new Date(Math.max(from.getTime(), minRange))
    to = new Date(Math.min(to.getTime(), maxRange))
    previousDay = null
  } else {
    previousDay = to = from = selectedDay
  }

  selectContributionRange(from, to)

  const url = baseURL()
  const params = buildContributionsURLParams(url.search.slice(1), {from, to, org})
  params.append('tab', 'overview')

  url.search = params.toString()
  loadContributionActivity(url.toString())
}

async function updateYearList(url: URL, params: URLSearchParams) {
  const container = document.getElementById('year-list-container')
  if (!container) {
    return
  }

  params.append('year_list', '1')
  url.search = params.toString()

  const html = await fetchSafeDocumentFragment(document, url.toString())
  container.textContent = ''
  container.append(html)
}

async function updateYearlyContributionsWithOrg(org: string | null) {
  const graphSelectedRange = getGraphSelectedDateRange()
  const graphRange = getCurrentGraphDateRange()

  const first = new Date(graphRange.first)
  const last = new Date(graphRange.last)

  await reloadYearlyContributions(first, last, org)

  if (graphSelectedRange) {
    const newFirst = new Date(graphSelectedRange.first)
    const newLast = new Date(graphSelectedRange.last)
    selectContributionRange(newFirst, newLast)
  }
}

on('click', '.js-org-filter-link', function (event) {
  event.stopPropagation()
  event.preventDefault()

  const link = event.currentTarget as HTMLAnchorElement

  const container = link.closest<HTMLElement>('.js-org-filter-links-container')!
  const currentSelectedOrg = container.querySelector('.js-org-filter-link.selected')
  const orgFilterURL = new URL(link.href, window.location.origin)
  const orgFilterParams = new URLSearchParams(orgFilterURL.search.slice(1))
  const selectedOrg = orgFilterParams.get('org')

  const currentDateRange = getSelectedDateRange()
  const from = new Date(currentDateRange.first)
  const to = new Date(currentDateRange.last)

  // Update the link we clicked to be the selected one, deselect any previously selected org link
  if (currentSelectedOrg) {
    currentSelectedOrg.classList.remove('selected')
  }
  if (link !== currentSelectedOrg) {
    link.classList.add('selected')
  }

  updateYearlyContributionsWithOrg(selectedOrg)

  const url = baseURL()
  const urlOptions: ContributionURLProperties = {org: selectedOrg, from: null, to: null}
  if (orgFilterParams.has('from')) {
    urlOptions.from = from
  }
  if (orgFilterParams.has('to')) {
    urlOptions.to = to
  }
  const params = buildContributionsURLParams(url.search.slice(1), urlOptions)

  url.search = params.toString()

  loadContributionActivity(url.toString())
  updateYearList(url, params)

  // Update URL with updated org selection
  addUrlToHistoryStack(url.toString())
})

on('click', '.js-year-link', function (event) {
  event.stopPropagation()
  event.preventDefault()

  const yearElement = event.currentTarget as HTMLAnchorElement

  const listEl = yearElement.closest<HTMLElement>('ul')!

  const currentYearElement = listEl.querySelector<HTMLElement>('.js-year-link.selected')!
  currentYearElement.classList.remove('selected')
  yearElement.classList.add('selected')

  loadYearUrl(yearElement.href)

  // Update URL with year selection
  addUrlToHistoryStack(yearElement.href)
})

function currentSelectedYearUrl(): string {
  const selectedYearEl = document.querySelector<HTMLAnchorElement>(
    '.js-profile-timeline-year-list .js-year-link.selected',
  )!
  return selectedYearEl.href || ''
}

function loadYearUrl(yearUrl: string) {
  const yearUrlSearchString = new URL(yearUrl, window.location.origin).search
  const searchParams = new URLSearchParams(yearUrlSearchString.slice(1))
  const org = searchParams.get('org')

  const fromParam = searchParams.get('from')!
  const toParam = searchParams.get('to')!
  const from = new Date(fromParam)
  const to = new Date(toParam)

  reloadYearlyContributions(from, to, org)

  const url = baseURL()
  const params = buildContributionsURLParams(url.search.slice(1), {from, to, org})
  params.append('tab', 'overview')

  url.search = params.toString()
  loadContributionActivity(url.toString())
}

function focusEvent(eventDiv: Element) {
  const rollupContainer = eventDiv.closest('.js-details-container')
  if (rollupContainer) {
    rollupContainer.classList.add('open')
  }
  const profileStickyBarHeight = 62
  const rect = eventDiv.getBoundingClientRect()
  const paddingFromTop = 10
  const topOffset = window.scrollY + rect.top - profileStickyBarHeight - paddingFromTop
  window.scrollTo(0, topOffset)
}

function focusLinkedEvent() {
  const urlHash = window.location.hash
  if (!urlHash || urlHash.indexOf('#event-') < 0) {
    return
  }
  const id = urlHash.slice(1, urlHash.length)
  const eventDiv = document.getElementById(id)
  if (!eventDiv) return
  focusEvent(eventDiv)
}

focusLinkedEvent()

window.addEventListener('hashchange', function (event) {
  const url = event.newURL || window.location.href
  const id = url.slice(url.indexOf('#') + 1, url.length)
  const eventDiv = document.getElementById(id)
  if (!eventDiv) return
  event.stopPropagation()
  focusEvent(eventDiv)
})

remoteForm('.js-show-more-timeline-form', async function (form, send) {
  await send.text()

  // Get active year off the new partial in the page, not the old
  // event.target form
  const newShowMoreForm = document.querySelector('.js-show-more-timeline-form')
  if (newShowMoreForm) {
    const activeYear = newShowMoreForm.getAttribute('data-year')!
    const selectedYearLink = document.querySelector<HTMLElement>('.js-year-link.selected')!
    const activeYearLink = document.querySelector<HTMLElement>(`#year-link-${activeYear}`)!
    selectedYearLink.classList.remove('selected')
    activeYearLink.classList.add('selected')

    const oldYear = form.getAttribute('data-year')

    if (activeYear !== oldYear) {
      // If we've gone back in the timeline to a previous year,
      // update the calendar and activity overview to that year
      const newFromStr = newShowMoreForm.getAttribute('data-from')!
      const newFrom = new Date(newFromStr)
      const newToStr = newShowMoreForm.getAttribute('data-to')!
      const newTo = new Date(newToStr)
      const org = newShowMoreForm.getAttribute('data-org')

      reloadYearlyContributions(newFrom, newTo, org)
    }
  }

  const feedback = [...document.querySelectorAll('#js-contribution-activity [data-sr-feedback]')].pop()! as HTMLElement
  feedback.focus()
  focusEvent(feedback)

  document.title = form.getAttribute('data-title') || ''
  addUrlToHistoryStack(form.getAttribute('data-url') || '')
})

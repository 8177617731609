import {observe} from '@github/selector-observer'
import {loaded} from '@github-ui/document-ready'
import {fromEvent} from '@github-ui/subscription'

observe('.js-navigation-popover', {
  constructor: HTMLElement,
  subscribe: popover => {
    asyncAdjustPopoverPosition(popover)
    return fromEvent(window, 'resize', () => adjustPopoverPosition(popover))
  },
})

observe('.js-profile-tab-count-container', tabContainer => {
  const popover = document.querySelector('.js-navigation-popover') as HTMLElement
  if (!popover) return

  tabContainer.addEventListener('tabs-loaded', () => {
    asyncAdjustPopoverPosition(popover)
  })
})

async function asyncAdjustPopoverPosition(popover: HTMLElement) {
  await loaded
  adjustPopoverPosition(popover)
}

function adjustPopoverPosition(popover: HTMLElement) {
  const target = document.querySelector('.js-navigation-popover-target') as HTMLElement
  const isTargetVisible = target && target.style.visibility !== 'hidden' && !target.classList.contains('v-hidden')

  if (isTargetVisible && !isSecurityTab()) {
    const targetRect = target.getBoundingClientRect()
    const popoverRect = popover.getBoundingClientRect()

    const top = targetRect.top + targetRect.height + 3 // nudging down a bit
    const left = targetRect.left + targetRect.width / 2 - popoverRect.width / 2 + 10 // nudging right a bit

    popover.style.top = `${top}px`
    popover.style.left = `${left}px`

    toggleVisibility(popover, true)
  } else {
    toggleVisibility(popover, false)
  }
}

function isSecurityTab() {
  return (
    document
      .getElementsByClassName('js-selected-navigation-item selected')[0]
      ?.getAttribute('data-selected-links')
      ?.includes('security') || false
  )
}

function toggleVisibility(popover: HTMLElement, visible: boolean) {
  if (visible) {
    popover.classList.remove('v-hidden')
  } else {
    popover.classList.add('v-hidden')
  }
}

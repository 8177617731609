import {controller, target, targets, attr} from '@github/catalyst'
import {changeValue, requestSubmit} from '@github-ui/form-utils'

interface IParticipantElement {
  [methodName: string]: () => Promise<void>
}

// Catalyst component that defers the submission of a child <form/> until a collection of child components have
// completed their work, such as input preprocessing or validation.
//
// Participating child components are identified by the `data-targets="waiting-form.prerequisites"` attribute. Each
// component must implement a method that returns a Promise. The name of the method may be specified by the
// `data-waiting-form-method` attribute, or the default of `getPromise`.
//
// The child <form> element must be identified by `data-target="waiting-form.form"`. Its submit button must be
// identified by `data-target="waiting-form.submit"` and have a `data-action` set to
// `click:waiting-form#submitPolitely`.
//
// Example:
//
// <waiting-form>
//   <form data-target="waiting-form.form">
//     <other-component data-targets="waiting-form.prerequisites" data-waiting-form-method="getSomePromise">
//        <input type="text" name="blah0" />
//     </other-component>
//     <another-component data-targets="waiting-form.prerequisites">
//        <input type="text" name="blah1" />
//     </another-component>
//     <button
//        type="submit"
//        data-target="waiting-form.submit"
//        data-action="click:waiting-form#submitPolitely"
//      >
//        Submit
//      </button>
//   </form>
// </waiting-form>
@controller
class WaitingFormElement extends HTMLElement {
  @target declare form: HTMLFormElement
  @targets declare prerequisites: HTMLElement[]
  @target declare submit: HTMLButtonElement

  async submitPolitely() {
    this.submit.disabled = true
    try {
      if (this.prerequisites.length > 0) {
        await Promise.all(this.prerequisites.map(participant => this.getPrerequisitePromise(participant)))
      }

      requestSubmit(this.form)
    } finally {
      this.submit.disabled = false
    }
  }

  private getPrerequisitePromise(participant: HTMLElement): Promise<void> {
    const promiseMethodName = participant.getAttribute('data-waiting-form-method') || 'getPromise'
    return (participant as unknown as IParticipantElement)[promiseMethodName]!()
  }
}

@controller
export class SocialAccountEditorElement extends HTMLElement {
  @target declare urlField: HTMLInputElement
  @target declare iconField: HTMLInputElement
  @targets declare iconOptions: HTMLElement[]
  @target declare iconGeneric: HTMLElement
  @target declare iconSpinner: HTMLElement
  @attr nodeinfoSoftwareUrl = ''

  private recognitionPromise = Promise.resolve()

  recognizeUrl() {
    this.recognitionPromise = new Promise(async resolve => {
      this.preprocessUrl()
      const matchingOption = await this.findMatchingSocialAccountIconOption(this.urlField.value)
      this.setChosenSocialIcon(matchingOption)
      resolve()
    })
  }

  resetToDefault() {
    if (this.urlField.value === this.urlField.defaultValue) return
    changeValue(this.urlField, this.urlField.defaultValue)
  }

  waitForRecognition(): Promise<void> {
    return this.recognitionPromise
  }

  private preprocessUrl(): void {
    const url = this.urlField.value.trim()
    if (url.length === 0) return

    if (!/^https?:\/\//.test(url)) {
      this.urlField.value = `https://${url}`
    }
  }

  private setChosenSocialIcon(chosenOption: Element) {
    const chosenKey = chosenOption.getAttribute('data-provider-key') || 'generic'

    for (const choice of this.iconOptions) {
      choice.hidden = choice !== chosenOption
    }
    this.iconSpinner.hidden = true
    this.iconField.value = chosenKey
  }

  private async findMatchingSocialAccountIconOption(url: string): Promise<Element> {
    for (const choice of this.iconOptions) {
      for (const patternElement of choice.querySelectorAll('[data-provider-pattern]')) {
        const pattern = new RegExp(patternElement.getAttribute('data-provider-pattern')!, 'i')
        if (pattern.test(url)) {
          return choice
        }
      }
    }

    // No direct URL pattern match. See if we should probe for the host for Nodeinfo.
    const nodeInfoIcon = await this.findNodeInfoIconOption(url)
    if (nodeInfoIcon) return nodeInfoIcon

    return this.iconGeneric
  }

  private async findNodeInfoIconOption(url: string): Promise<Element | null> {
    if (this.nodeinfoSoftwareUrl.trim().length === 0) return null

    const nodeInfoCandidates = new Map<string, Element>()
    for (const choice of this.iconOptions) {
      for (const nodeInfoElement of choice.querySelectorAll('[data-try-nodeinfo-pattern]')) {
        const pattern = new RegExp(nodeInfoElement.getAttribute('data-try-nodeinfo-pattern')!, 'i')
        const expectedSoftware = nodeInfoElement.getAttribute('data-nodeinfo-software')

        const match = url.match(pattern)
        if (match && expectedSoftware) {
          nodeInfoCandidates.set(expectedSoftware, choice)
        }
      }
    }
    if (nodeInfoCandidates.size === 0) return null

    let parsedURL: URL | null = null
    try {
      parsedURL = new URL(url, window.location.origin)
    } catch {
      return null
    }

    this.showSpinner()

    const u = new URL(this.nodeinfoSoftwareUrl, window.location.origin)
    u.searchParams.set('host', parsedURL.host)
    const checkResponse = await fetch(u, {
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        Accept: 'application/json',
      },
    })

    const checkDocument = await checkResponse.json()
    const softwareName = checkDocument?.['software_name']
    if (softwareName) {
      return nodeInfoCandidates.get(softwareName) || null
    } else {
      return null
    }
  }

  showSpinner() {
    for (const choice of this.iconOptions) {
      choice.hidden = true
    }
    this.iconSpinner.hidden = false
  }
}

import {announceFromElement, type ElementWithAriaNotify} from '@github-ui/aria-live'
import {observe} from '@github/selector-observer'
import {isFeatureEnabled} from '@github-ui/feature-flags'

observe('.js-starred-repos-search-results', function (el) {
  // use arianotify if feature
  if (isFeatureEnabled('ariaNotify_partial_migration') && 'ariaNotify' in Element.prototype) {
    ;(el as ElementWithAriaNotify).ariaNotify(el.textContent || '', {
      interrupt: 'all',
      priority: 'important',
    })
  } else {
    announceFromElement(el as HTMLElement)
  }
})

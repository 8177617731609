import type SortableI from '@github/sortablejs'
import type {SortableEvent} from '@github/sortablejs'
import {observe} from '@github/selector-observer'
import {on} from 'delegated-events'
import {announce} from '@github-ui/aria-live'

let sortable: SortableI | null = null
let siblingListItem: Node | null = null

function handleStart(event: SortableEvent) {
  const {item, oldIndex} = event
  const {parentNode} = item
  siblingListItem = parentNode!.children[oldIndex! + 1]!
}

async function handleUpdate(event: SortableEvent): Promise<void> {
  const {oldIndex, newIndex, item} = event

  if (oldIndex === newIndex) {
    return
  }

  const form = item.closest<HTMLFormElement>('.js-pinned-items-reorder-form')!
  const container = form.closest<HTMLElement>('.js-pinned-items-reorder-container')!
  const spinner = container.querySelector<HTMLElement>('.js-pinned-items-spinner')
  const notice = container.querySelector<HTMLElement>('.js-pinned-items-reorder-message')
  const showFeedback = spinner && notice

  if (showFeedback) {
    notice.textContent = ''
    spinner.style.display = 'inline-block'
  }
  sortable!.option('disabled', true)

  const response = await fetch(form.action, {
    method: form.method,
    body: new FormData(form),
    headers: {
      'X-Requested-With': 'XMLHttpRequest',
    },
  })
  if (!response.ok) {
    if (showFeedback) {
      notice.textContent = notice.getAttribute('data-error-text') || ''
      spinner.style.display = 'none'
    }

    const list = item.parentNode!

    if (siblingListItem) {
      list.insertBefore(item, siblingListItem)
    } else {
      list.appendChild(item)
    }
    return
  }
  if (showFeedback) {
    notice.textContent = notice.getAttribute('data-success-text') || ''
    announce(notice.textContent)
    spinner.style.display = 'none'
  }
  sortable!.option('disabled', false)
}

observe('.js-pinned-items-reorder-list', {
  async add(el) {
    const {Sortable} = await import('../sortable-behavior')
    sortable = Sortable.create(el, {
      animation: 150,
      item: '.js-pinned-item-list-item',
      handle: '.js-pinned-item-reorder',
      onUpdate: handleUpdate,
      onStart: handleStart,
      chosenClass: 'is-dragging',
    })
  },
})

on('submit', '.js-pinned-items-reorder-form', function (event) {
  event.preventDefault()
})

on('click', '.js-pinned-item-list-item .js-sortable-button', async function ({currentTarget: button}) {
  const {moveWithButton} = await import('../sortable-behavior')
  moveWithButton(button as HTMLButtonElement, button.closest<HTMLElement>('.js-pinned-item-list-item')!, handleUpdate)
})

import {observe} from '@github/selector-observer'

async function load(el: Element) {
  const {initializeOverviewGraphContainer} = await import('./contributions-spider-graph')
  initializeOverviewGraphContainer(el)
}

observe('.js-activity-overview-graph-container', el => {
  load(el)
})

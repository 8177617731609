import {on} from 'delegated-events'

on('click', '.js-new-user-contrib-example', async function (event) {
  const container = document.querySelector<HTMLElement>('.js-calendar-graph')!
  if (container.classList.contains('sample-graph')) {
    return
  }
  container.classList.add('sample-graph')

  const url = event.currentTarget.getAttribute('data-url')!

  const response = await fetch(url, {
    headers: {'X-Requested-With': 'XMLHttpRequest'},
  })
  if (!response.ok) {
    container.classList.remove('sample-graph')
    return
  }
  const html = await response.text()

  const tmp = document.createElement('div')
  tmp.innerHTML = html

  const svg = container.querySelector('.js-calendar-graph-table')

  svg?.replaceWith(tmp.children[0]!)
})
